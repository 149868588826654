import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/shared/SEO"
import Header from "../components/shared/Header"
import Content from "../components/blogpost/Content"
import BlogPostsList from "../components/shared/BlogPostsList"

const BlogPost = ({ data }) => {
  const { title, image } = data.contentfulBlogPost

  return (
    <Layout>
      <SEO title={title} />
      <Header title={title} image={image} />
      <Content data={data} />
      {data.allContentfulBlogPost.edges.length > 0 ? (
        <BlogPostsList title="Latest Blog Posts" data={data} />
      ) : (
        ""
      )}
    </Layout>
  )
}
export default BlogPost

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
      body {
        childMarkdownRemark {
          html
        }
      }
      image {
        fluid(maxWidth: 900, quality: 90) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      tags
      createdAt
    }
    allContentfulBlogPost(limit: 6, filter: { slug: { ne: $slug } }) {
      edges {
        node {
          id
          title
          slug
          body {
            body
            childMarkdownRemark {
              plainText
            }
          }
          image {
            fluid(maxWidth: 350, quality: 90) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          tags
          createdAt
        }
      }
    }
  }
`
