import React from "react"
import { colors } from "../../../assets/themes/default"
import styled from "@emotion/styled"
import Text from "../Text"

const ListItems = ({ label, items }) => {
  const styleWrapper = {
    borderBottom: `1px solid ${colors.grayLight}`,
    display: `flex`,
    paddingBottom: `10px`,
    paddingTop: `10px`,
  }

  const styleWrapperLabel = {
    alignItems: "flex-start",
    display: `flex`,
    flexBasis: `30%`,
  }

  const styleWrapperItems = {
    alignItems: `flex-end`,
    display: `flex`,
    flexBasis: `70%`,
    justifyContent: `flex-end`,
  }

  const styleLabel = {
    margin: `0px`,
    textTransform: `uppercase`,
  }

  const styleItem = {
    margin: `0px`,
    paddingLeft: `10px`,
  }

  const Wrapper = styled.div`
    ${styleWrapper}
  `

  const WrapperLabel = styled.div`
    ${styleWrapperLabel}
  `

  const Label = styled(Text)`
    ${styleLabel}
  `

  const WrapperItems = styled.div`
    ${styleWrapperItems}
  `

  const Item = styled(Text)`
    ${styleItem}
  `

  return (
    <Wrapper>
      <WrapperLabel>
        <Label type="xsmall" color={colors.grayDark}>
          {label}
        </Label>
      </WrapperLabel>
      <WrapperItems>
        {items.map((item, index) => (
          <Item type="xsmall" key={index} noBreak>
            {item}
          </Item>
        ))}
      </WrapperItems>
    </Wrapper>
  )
}

export default ListItems
