import React from "react"
import styled from "@emotion/styled"
import PostDate from "../../shared/PostDate"
import Section from "../../shared/Section"
import ListItems from "../../shared/ListItems"
import Text from "../../shared/Text"

const Content = ({ data }) => {
  const { title, body, tags, createdAt } = data.contentfulBlogPost

  const styleBody = {
    maxWidth: `700px`,
    textAlign: "left",
    padding: `0px 20px 0px 20px`,
  }

  const Body = styled.div`
    ${styleBody}
  `

  return (
    <>
      <Section title={title}>
        <Body>
          <Text>
            <span>
              <div dangerouslySetInnerHTML={{
                __html: body.childMarkdownRemark.html,
              }}></div>
            </span>
          </Text>
          <PostDate date={createdAt} />
          <ListItems label="Tags" items={tags} />
        </Body>
      </Section>
    </>
  )
}

export default Content
