import React from "react"
import Section from "../Section"
import PostPreview from "../PostPreview"

const BlogPostsList = ({ title, data }) => {
  return (
    <Section title={title}>
      {data.allContentfulBlogPost.edges.map(({ node: blogPost }) => (
        <div key={blogPost.id}>
          <PostPreview
            title={blogPost.title}
            content={blogPost.body.childMarkdownRemark.plainText.trunc(
              200,
              true
            )}
            url={`/blogpost/${blogPost.slug}`}
            image={blogPost.image}
            publishDate={blogPost.createdAt}
          />
        </div>
      ))}
    </Section>
  )
}

export default BlogPostsList
